const $ = window.$
$('.keyvisual-carousel').slick({
  mobileFirst: false,
  autoplay: true,
  responsive: [{
    breakpoint: 768,
    settings: 'unslick'
  }],
  prevArrow: '<button type="button" class="btn btn-slick slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="23.913" height="66.611" viewBox="0 0 23.913 66.611"><path d="M19.3,0l4.445,3.459-17.78,28.9L23.913,62.812l-4.67,3.8L0,32.152Z" fill="rgba(255,255,255,0.5)" fill-rule="evenodd"/></svg></button>',
  nextArrow: '<button type="button" class="btn btn-slick slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="23.913" height="66.611" viewBox="0 0 23.913 66.611"><path d="M4.614,0,.169,3.459l17.78,28.9L0,62.812l4.67,3.8L23.913,32.152Z" fill="rgba(255,255,255,0.5)" fill-rule="evenodd"/></svg></button>'
})
$('.member-carousel').slick({
  mobileFirst: true,
  centerMode: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  adaptiveHeight: true,
  responsive: [{
    breakpoint: 1199,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      centerMode: false
    }
  }],
  prevArrow: '<button type="button" class="btn btn-slick slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.007" height="44.586" viewBox="0 0 16.007 44.586"><path d="M12.918,0l2.975,2.316L3.992,21.658,16.007,42.044l-3.126,2.543L0,21.521Z" fill="rgba(255,255,255,0.5)" fill-rule="evenodd"/></svg></button>',
  nextArrow: '<button type="button" class="btn btn-slick slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.007" height="44.586" viewBox="0 0 16.007 44.586"><path d="M3.088,0,.113,2.316l11.9,19.342L0,42.044l3.126,2.543L16.007,21.521Z" fill="rgba(255,255,255,0.5)" fill-rule="evenodd"/></svg></button>'
})
const mobileTestimonialSettings = {
  centerMode: false,
  slidesToShow: 1,
  autoplay: false,
  infinite: false,
  arrows: false,
  appendDots: $('#testimonial-nav .dot-wrapper'),
  dots: true,
  dotsClass: 'testimonial-dots',
  mobileFirst: true,
  responsive: [{
    breakpoint: 1199,
    settings: 'unslick'
  }]
}
const mobileTestimonial = $('.mobile-testimonial')
const mobileTestimonialSlick = mobileTestimonial.slick(mobileTestimonialSettings)

$(window).on('resize', function () {
  initTestimonialSlider()
})
//  Reload Page on Orientationchange for Tabletscreens

function initTestimonialSlider () {
  if (window.outerWidth < 1200) {
    if (!mobileTestimonialSlick.hasClass('slick-initialized')) {
      mobileTestimonial.slick(mobileTestimonialSettings)
    }
  }
}
